<template>
  <div class="AudioAndText-wrap row items-center justify-between">
    <div class="content">
      <div class="name">{{ name }}</div>
      <div class="content-right">
        <span class="text" :title="fullText" @click="showFullText()">「{{ text }}」</span>
        <span class="btn-wrap">
          <!-- 播放鍵與暫停鍵 -->
          <q-icon v-if="playingAudio" class="stopBtn" name="stop" @click="controlBtn" />
          <img v-else :ref="'playBtn'" class="playBtn" :class="{ disableBtn: !audioSrc }" src="../../assets/img/pinkl_play_btn.png" alt="play_btn" @click="controlBtn" />
        </span>
      </div>
    </div>
    <UserSentenceViewer v-model="dialog.show" :title="name" :content="fullText" :audioSrc="audio" />
    <!-- 音檔(不顯示) -->
    <audio v-show="false" :ref="'myAudio'" controls :src="audioSrc"></audio>
  </div>
</template>

<script>
import { parseAudioLink } from '@/lib/utils';
import UserSentenceViewer from '@/components/NewMainPage/UserSentenceViewer.vue';

export default {
  name: 'AudioAndText',
  props: ['name', 'text', 'fullText', 'audio', 'index'],
  components: { UserSentenceViewer },
  data() {
    return {
      playingAudio: false,
      playIndex: 0,
      dialog: {
        show: false
      }
    };
  },
  mounted() {
    const self = this;
    const myAudio = this.$refs.myAudio;
    // 監聽音檔結束
    myAudio.addEventListener('ended', this.onAudioEnd, false);
    const playBtn = this.$refs.playBtn;
    if (this.index === 1) {
      setTimeout(() => {
        playBtn.classList.add('animation');
      }, 4500);
    } else if (this.index === 0) {
      setTimeout(() => {
        playBtn.classList.add('animation');
      }, 9000);
    } else {
      setTimeout(() => {
        playBtn.classList.add('animation');
      }, 13500);
    }
  },
  computed: {
    audios() {
      if (Array.isArray(this.audio)) {
        return this.audio;
      } else {
        return [this.audio];
      }
    },
    audioSrc() {
      return parseAudioLink(this.audios[this.playIndex]);
    }
  },
  methods: {
    controlBtn() {
      if (this.playingAudio) {
        this.pauseAudioMedia();
      } else {
        // 若沒設定音檔連結，則自動忽略
        if (this.audioSrc) {
          this.startAudioMedia();
        }
      }
    },
    showFullText() {
      this.dialog.show = true;
    },
    // 播放音檔
    startAudioMedia() {
      const myAudio = this.$refs.myAudio;
      this.$nextTick(() => {
        myAudio.play();
        this.playingAudio = true;
      });
    },
    // 暫停音檔
    pauseAudioMedia() {
      this.playingAudio = false;
      const myAudio = this.$refs.myAudio;
      myAudio.pause();
    },
    onAudioEnd() {
      if (this.playingAudio) {
        this.playIndex += 1;
        if (this.audios.length >= this.playIndex) {
          this.playIndex;
        }
        const myAudio = this.$refs.myAudio;
        this.$nextTick(() => {
          myAudio.play();
        });
      }
    }
  },
  beforeDestroy() {
    const self = this;
    const myAudio = this.$refs.myAudio;
    myAudio.removeEventListener('ended', this.onAudioEnd, false);
  }
};
</script>
<style scoped lang="scss">
.AudioAndText-wrap {
  background: rgba(255, 255, 255, 0.32);
  border-radius: 15px;
  width: 90%;
  height: 50px;
  margin: 0 auto 17px auto;
  overflow-y: auto;
  .name {
    color: #504ea9;
    font-size: 16px;
  }
  .text {
    font-weight: 500;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    // 確保點擊區域
    min-width: 100px;
    min-height: 20px;
  }
  .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    .content-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .btn-wrap {
    vertical-align: middle;
    display: inline-block;
    height: 35px;
    width: 35px;
    text-align: center;
    padding-top: 1px;
  }
}
.stopBtn {
  background: linear-gradient(0deg, #fd3a84 0%, #fdcfc2 100%);
  color: #fbd8de;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  line-height: 28px;
  font-size: 18px;
}
.playBtn {
  height: 29px;
  border-radius: 29px;
  &.animation {
    animation: heartbeat 7.5s infinite;
  }
}
.disableBtn {
  opacity: 0.4;
}
// 類似心跳的動畫
@keyframes heartbeat {
  0% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px #fbd8de;
    height: 29px;
    width: 29px;
  }
  13% {
    opacity: 1;
    box-shadow: 0px 0px 3px 3px #fbd8de;
    height: 32px;
    width: 32px;
  }
  20% {
    opacity: 1;
    box-shadow: 0px 0px 3px 3px #fbd8de;
    height: 32px;
    width: 32px;
  }
  33% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px #fbd8de;
    height: 29px;
    width: 29px;
  }
  100% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px #fbd8de;
    height: 29px;
    width: 29px;
  }
}
</style>
<style lang="scss"></style>
