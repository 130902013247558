<template>
  <div class="UserSentence-wrap">
    <q-dialog v-model="show">
      <q-card class="dialog-bg">
        <q-card-section class="row items-center q-pb-none">
          <div class="authorName">{{ title }}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="sentenceContent" v-html="contentHTML"></div>
          <!-- <DialogAudioPlayer :src="audioSrc" /> -->
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogAudioPlayer from './DialogAudioPlayer.vue';
import { parseContentToHTML } from '@/lib/utils';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { DialogAudioPlayer },
  props: ['value', 'title', 'content', 'audioSrc'],
  data() {
    return {
      audioReady: false
    };
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
        if (this.value != v) {
          this.$emit('change', v);
        }
      },
      get() {
        return this.value;
      }
    },
    contentHTML() {
      return parseContentToHTML(this.content);
    }
  },
  watch: {
    audioSrc() {}
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.authorName {
  color: #504ea9;
  font-size: 16px;
}
.sentenceContent {
  font-size: 14px;
  color: black;
  text-align: left;
  margin-bottom: 12px;
}
.dialog-bg {
  background-color: #f0dcda;
}
</style>
