<template>
  <div class="ItemWave1-wrap">
    <div class="header">
      <!--Waves Container-->
      <div>
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
            <template v-if="multipleWave">
              <use v-if="opacity >= 0.9" xlink:href="#gentle-wave" x="48" y="0" :fill="`rgba(${r},${g},${b},0.9)`" />
              <use v-if="opacity >= 0.7" xlink:href="#gentle-wave" x="48" y="3" :fill="`rgba(${r},${g},${b},0.7)`" />
              <use v-if="opacity >= 0.5" xlink:href="#gentle-wave" x="48" y="5" :fill="`rgba(${r},${g},${b},0.5)`" />
              <use v-if="opacity >= 0.3" xlink:href="#gentle-wave" x="48" y="7" :fill="`rgba(${r},${g},${b},0.3)`" />
            </template>
            <template v-else>
              <use v-if="opacity >= 0.9" xlink:href="#gentle-wave" x="48" y="0" :fill="`rgba(${r},${g},${b},0.9)`" />
              <use v-else-if="opacity >= 0.7" xlink:href="#gentle-wave" x="48" y="3" :fill="`rgba(${r},${g},${b},0.7)`" />
              <use v-else-if="opacity >= 0.5" xlink:href="#gentle-wave" x="48" y="5" :fill="`rgba(${r},${g},${b},0.5)`" />
              <use v-else xlink:href="#gentle-wave" x="48" y="7" :fill="`rgba(${r},${g},${b},0.3)`" />
            </template>
          </g>
        </svg>
      </div>
      <!--Waves end-->
    </div>
    <!--Header ends-->
  </div>
</template>

<script>
export default {
  name: 'ItemWave1',
  props: ['r', 'g', 'b', 'opacity', 'multipleWave'],
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.ItemWave1-wrap {
  position: absolute;
}
// 海浪波浪 css start
.header {
  position: relative;
  // text-align: center;
  // background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
  // color: white;
}
.waves {
  position: relative;
  width: 100%;
  // height: 15vh;
  height: 40px;
  min-height: 40px;
  margin-bottom: -6px; /*Fix for safari gap*/
  // min-height: 100px;
  // max-height: 150px;
}

/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}
// 海浪波浪 css end
</style>
<style lang="scss"></style>
