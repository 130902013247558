<template>
  <div class="MainPageHeader-wrap">
    <!-- 標題 -->
    <div class="headerTitle">
      <span>{{ name }}</span>
      的靈魂香氛是
    </div>
    <!-- 香氛名字 -->
    <div class="personalType" :style="{ color: mainFlowerColor }">{{ personalType }}</div>
    <img class="mainFlowerImg" :src="mainFlower" :style="mainFlowerStyle" alt="Salvia_img" />
    <img class="smallFlowerImg" src="../../assets/img/img-flower.png" alt="flower" />
    <img class="smallFlowerImg_1" src="../../assets/img/img-flower.png" alt="flower" />
  </div>
</template>

<script>
export default {
  name: 'MainPageHeader',
  props: ['name', 'personalType', 'personalTypeKey'],
  components: {},
  data() {
    return {
      img: {
        flower: {
          // key 為 人格特質的名稱縮寫
          Con: require('@/assets/img/flower/D1.png'),
          Emo: require('@/assets/img/flower/D2.png'),
          Agr: require('@/assets/img/flower/D3.png'),
          Ext: require('@/assets/img/flower/D4.png'),
          Ope: require('@/assets/img/flower/D5.png')
        }
      },
      color: {
        flower: {
          Con: '#236377',
          Emo: '#DC7688',
          Agr: '#72C52C',
          Ext: '#7136B9',
          Ope: '#FA9A11'
        }
      },
      flowerStyleMap: {
        D4: {
          right: '-15px',
          top: '18px'
        },
        D5: {
          top: '60px'
        }
      }
    };
  },
  mounted() {},
  computed: {
    mainFlower() {
      return this.img.flower[this.personalTypeKey];
    },
    mainFlowerStyle() {
      return this.flowerStyleMap[this.personalTypeKey] || {};
    },
    mainFlowerColor() {
      return this.color.flower[this.personalTypeKey] || '#236377';
    }
  }
};
</script>
<style scoped lang="scss">
.MainPageHeader-wrap {
  position: relative;
  width: 100%;
}
.headerTitle {
  color: #2d234c;
  font-size: 18px;
  line-height: 30px;
  padding-top: 29px;
  padding-left: 25px;
  span {
    color: #504ea9;
  }
}
.personalType {
  color: #f9b3ae;
  font-weight: 900;
  line-height: 73px;
  font-size: 64px;
  padding-left: 30px;
}
.mainFlowerImg {
  position: absolute;
  right: 0;
  top: 0;
}
.smallFlowerImg {
  position: absolute;
  right: 153px;
  top: 75px;
}
.smallFlowerImg_1 {
  position: absolute;
  right: 168px;
  top: 52px;
}
@media (max-width: 359px) {
  .mainFlowerImg {
    width: 132px;
  }
  .smallFlowerImg {
    right: 110px;
    top: 69px;
  }
  .smallFlowerImg_1 {
    right: 126px;
    top: 49px;
  }
}
</style>
<style lang="scss"></style>
