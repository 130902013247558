<template>
  <div class="NewMainPage-wrap">
    <template v-if="pageData">
      <!-- 標題 -->
      <MainPageHeader :name="nickname" :personalType="personalType" :personalTypeKey="personalTypeKey" />
      <!-- 波浪背景 -->
      <WaveBg class="WaveBg-component" />
      <!-- 分析結果內容 -->
      <div class="container">
        <AnalyzeResult :title="'你的分析結果'" :text_1="content1" :text_2="content2" />
        <div class="smallSentence">
          你的高維度思考感染了人們，
          <br />
          總共幫助他們轉化了缺點帶來的負面心境
          <span>{{ times }}</span>
          次
        </div>
        <AnalyzeResult :title="'你的助人金句'" :disadvantage="disadvantage" :content="`「${userPrimarySentence}」`" :myAudio="myAudio" />
        <div class="smallTitle">你的共鳴夥伴</div>
        <AudioAndText v-for="(item, index) in audioAndTextData" :key="item.name + index" :name="item.name" :text="item.text" :fullText="item.fullText" :audio="item.audio" :index="index" />
        <div class="m702-badge-container">
          <div class="m702-badge-title">
            徽章庫
          </div>
          <div class="m702-badge-subtitle">
            你已蒐集的李欣頻老師彩蛋音檔
            <br />
            共 {{ m702BadgeCount }} 則
          </div>
          <div class="m702-badge-wrap" v-click-outside="stopAudio">
            <div class="m702-badge" v-for="(item, i) in m702Badge" :key="i">
              <img class="m702-badge-img" :class="item.classes" :src="item.img" :alt="'m702-badge-' + i" @click="item.times >= 5 && playAudio(item.audio)" />
              <div class="m702-badge-label" v-text="item.label"></div>
            </div>
          </div>
          <div class="m702-badge-subtitle">
            點擊各徽章｜再聽一次
          </div>
        </div>
      </div>
      <!-- 黃色生物背景 -->
      <img class="yellowItem" src="../../assets/img/yellow_item_left.png" alt="" />
      <!-- 了解更多(footer) -->
      <NewMainPageBottom class="NewMainPageBottom-component" />
    </template>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapActions } from 'vuex';
import audioTest from '../../assets/media/11296260_MotionElements_heaven-piano_preview.mp3';
import MainPageHeader from '../../components/NewMainPage/MainPageHeader.vue';
import WaveBg from '../../components/NewMainPage/WaveBg.vue';
import AnalyzeResult from '../../components/NewMainPage/AnalyzeResult.vue';
import AudioAndText from '../../components/NewMainPage/AudioAndText.vue';
import NewMainPageBottom from '../../components/NewMainPage/NewMainPageBottom.vue';
import { parseContentToHTML, parseAudioLink } from '@/lib/utils';

export default {
  name: 'NewMainPage',
  directives: {
    ClickOutside
  },
  components: { MainPageHeader, WaveBg, AnalyzeResult, AudioAndText, NewMainPageBottom },
  data() {
    return {
      nickname: '',
      personalType: '',
      personalTypeKey: '',
      content1: '',
      content2: '',
      userPrimarySentence: '',
      disadvantage: '',
      flowerImg: '',
      text_2: '想法<span style="color:#E78686">高貴優雅</span>，能怎樣幫他們轉化缺點文案文案文案文案文案文案文案文案文案文案。',
      times: 30,
      myAudio: audioTest,
      m702Badge: [
        {
          audio: 'https://voiss-mini.s3.ap-southeast-1.amazonaws.com/Xinpin_ProAudio/Xinpin2021_7-1.mp3',
          img: require('@/assets/img/m702-badge-1.png'),
          label: '太陽',
          times: 0,
          classes: []
        },
        {
          audio: 'https://voiss-mini.s3.ap-southeast-1.amazonaws.com/Xinpin_ProAudio/Xinpin2021_7-2.mp3',
          img: require('@/assets/img/m702-badge-2.png'),
          label: '星星魔法陣',
          times: 0,
          classes: []
        },
        {
          audio: 'https://voiss-mini.s3.ap-southeast-1.amazonaws.com/Xinpin_ProAudio/Xinpin2021_7-3.mp3',
          img: require('@/assets/img/m702-badge-3.png'),
          label: '草莓披薩',
          times: 0,
          classes: []
        }
      ],
      m702Times: [0, 0, 0],
      audioAndTextData: [
        // 範例資料
        // {
        //   name: 'Amie',
        //   text: '話語話語話語話語話語話語...',
        //   audio: audioTest
        // },
        // {
        //   name: 'Jack',
        //   text: '話語話語話語話語話語話語...',
        //   audio: audioTest
        // },
        // {
        //   name: 'Sue',
        //   text: '話語話語話語話語話語話語...',
        //   audio: audioTest
        // }
      ]
    };
  },
  computed: {
    reportId() {
      return this.$store.state.ReportPageModule.reportId;
    },
    pageData() {
      return this.$store.state.ReportPageModule.introduction;
    }
  },
  created() {
    this.$audio = new Audio();
  },
  async mounted() {
    if (this.reportId && !this.$route.query.s) {
      let s = this.reportId;
      this.$router.replace(this.$route.path + `?s=${s}`);
    }
    if (!this.pageData) {
      let reportId = this.reportId || this.$route.query.s;
      await this.updateReportData({ id: reportId, reports: ['introduction'] });
    } else {
      this.updatePage();
    }
  },
  beforeDestroy() {
    if (this.$audio) {
      this.$audio.pause();
      this.$audio = null;
    }
  },
  watch: {
    pageData(newPageData) {
      if (newPageData) {
        this.updatePage(newPageData);
      }
    }
  },
  methods: {
    ...mapActions('ReportPageModule', ['updateReportData']),
    stopAudio() {
      this.$audio.pause();
    },
    playAudio(src) {
      if (src) {
        src = parseAudioLink(src);
        if (this.$audio.src === src) {
          if (!this.$audio.paused) {
            this.$audio.pause();
          } else {
            this.$audio.play();
          }
        } else {
          this.$audio.src = src;
          this.$audio.currentTime = 0;
          this.$audio.play();
        }
      }
    },
    updatePage(newPageData = this.pageData) {
      let data = newPageData;
      this.nickname = data.nickname;
      let personality = data.personality || {};
      this.personalTypeKey = personality.type;
      this.personalType = personality.title;
      let textLimit = 10;
      this.audioAndTextData = data.similarPeoples.map(item => {
        let fullText = item.sentence || '';
        let text = fullText.substr(0, textLimit);
        if (fullText.length > textLimit) {
          text += '...';
        }

        return {
          name: item.nickname,
          text,
          fullText,
          audio: item.audioSrc
        };
      });
      this.times = data.userPlayTimes;
      this.myAudio = data.userSpeechAudio;
      this.content1 = parseContentToHTML(data.content1);
      this.content2 = parseContentToHTML(data.content2);
      this.disadvantage = data.disadvantage;
      this.userPrimarySentence = data.userSpeech;
      this.m702Times = data.m702Times || [0, 0, 0];
      let m702Times = this.m702Times;
      this.m702BadgeCount = 0;
      for (let i = 0; i < m702Times.length; i++) {
        let info = this.m702Badge[i];
        let times = m702Times[i];
        info.times = times;
        if (times >= 5) {
          info.classes = [];
          this.m702BadgeCount += 1;
        } else {
          info.classes = ['m702-badge-img-disabled'];
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
@mixin mini_scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(182, 183, 205, 1);
    border-radius: 7px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(182, 183, 205, 1);
  }
}
.NewMainPage-wrap {
  background-image: url('../../assets/img/new_bg_item.png');
  height: 100vh;
  max-width: 414px;
  background-repeat: no-repeat;
  background-size: 46%;
  overflow: hidden;
  position: relative;
}
.WaveBg-component {
  height: calc(100vh - 166px);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.container {
  height: calc(100vh - 166px - 45px);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 45px;
  overflow-y: auto;
  @include mini_scroll;
  z-index: 10;
  padding-top: 15px;
  .smallSentence {
    font-size: 12px;
    line-height: 15px;
    color: #868686;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 22px 10px 30px 10px;
    span {
      color: #2d234c;
      font-size: 24px;
    }
  }
}
.smallTitle {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 15px 0;
}
.yellowItem {
  position: absolute;
  bottom: 159px;
}
.NewMainPageBottom-component {
  position: absolute;
  bottom: 0;
  padding: 0 5%;
}
.m702-badge-container {
  padding: 24px;
  text-align: center;
  .m702-badge-title {
    font-size: 20px;
  }
  .m702-badge-subtitle {
    font-size: 14px;
  }
  .m702-badge-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .m702-badge-img {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.8;
    }
    &.m702-badge-img-disabled {
      cursor: default;
      opacity: 0.3;
    }
  }
  .m702-badge {
  }
  .m702-badge-label {
    margin-top: 8px;
    text-align: center;
  }
}
</style>
<style></style>
