export function delay(t) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, t);
  });
}

/**
 * 接收 #FF00FF 之類的色碼，並轉換成 [255, 0, 255] 格式的RGB資料
 * @param {String} color css color string
 * @returns <Number[]>
 */
export function colorRGB(color) {
  const c = color;
  if (!c) return null;
  if (c[0] === '#') {
    if (c.length === 7) {
      return [parseInt(c.substr(1, 2), 16), parseInt(c.substr(3, 2), 16), parseInt(c.substr(5, 2), 16)];
    } else if (c.length === 4) {
      return [parseInt(c[1], 16) * 16, parseInt(c[2], 16) * 16, parseInt(c[3], 16) * 16];
    } else {
      return null;
    }
  } else {
    const rgb = c
      .replace(/rgb[a]?/, '')
      .replace(/[()]/g, '')
      .split(',')
      .map(num => parseInt(num));
    if (rgb < 3) {
      return null;
    } else {
      return rgb;
    }
  }
}

/**
 * @param {String} content
 */
export function escapeContent(content) {
  return content
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/\n/g, '<br>');
}

/**
 * @typedef {Object} parseContentToHTMLOptions
 */

/**
 * @param {String[]} content
 * @param {parseContentToHTMLOptions} opts
 */
export function parseContentToHTML(content, opts = {}) {
  if (!content) return '';
  if (!Array.isArray(content)) {
    content = [content];
  }
  const htmlPartsList = [];
  for (const part of content) {
    if (part) {
      if (typeof part === 'string') {
        htmlPartsList.push(escapeContent(part));
      } else if (typeof part === 'object') {
        let { text, class: cls } = part;
        text = escapeContent(text);
        let partHtml;
        if (cls) {
          partHtml = `<span class="${cls}">${text}</span>`;
        } else {
          partHtml = text;
        }
        htmlPartsList.push(partHtml);
      }
    }
  }
  return htmlPartsList.join('');
}

/**
 * 對 S3 的連結做轉換
 * @param {String} url
 * @returns {String}
 */
export function parseAudioLink(url) {
  url = url || '';
  let m = url.match(/^https:\/\/(.*).s3.(ap-.*-\d+).amazonaws.com\/(.*)/);
  if (m) {
    let bucket = m[1];
    let location = m[2];
    let path = m[3];
    return `https://${bucket}.storage.googleapis.com/${path}`
  } else {
    return url;
  }
}

export function checkAudioAvailable(src) {
  console.log('checkAudioAvailable', src);
  return new Promise((resolve, reject) => {
    let audio = new Audio();
    let deleted = false;
    let result = {
      src,
      meta: {
        duration: Infinity
      }
    };
    audio.addEventListener('durationchange', () => {
      let meta = result.meta;
      if (Number.isFinite(audio.duration)) {
        meta.duration = audio.duration;
        remove();
        resolve(result);
      }
    });
    // audio.addEventListener('canplaythrough', () => {
    //   remove();
    //   resolve(result);
    // });
    audio.addEventListener('error', err => {
      err.info = result;
      remove();
      reject(err);
    });
    audio.src = src;
    audio.currentTime = 24 * 60 * 60;
    document.body.appendChild(audio);

    function remove() {
      if (!deleted) {
        deleted = true;
        document.body.removeChild(audio);
      }
    }
  });
}
