<template>
  <div class="NewMainPageBottom-wrap row items-center justify-between" v-touch-pan.mouse="handlePan" @click="toReportPage">
    <div class="blank"></div>
    <div class="col-4 row items-center justify-between">
      <span class="text">了解</span>
      <img src="../../assets/img/chevron_down.png" alt="arrow_down_icon" />
      <span class="text">更多</span>
    </div>
    <img src="../../assets/img/Voiss_Logo.png" alt="logo" />
  </div>
</template>

<script>
import router from '../../router/index';
export default {
  name: 'NewMainPageBottom',
  props: [],
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    // 偵測使用者向哪裡滑的偵測器
    handlePan({ evt, ...info }) {
      if (process.env.NODE_ENV === 'development') console.log(info);
      // native Javascript event
      if (process.env.NODE_ENV === 'development') console.log(evt);
      if (info.direction === 'up') {
        setTimeout(() => {
          this.toReportPage();
        }, 300);
      }
    },
    // 至報告頁面
    toReportPage() {
      router.push('/NewReportPage');
    }
  }
};
</script>
<style scoped lang="scss">
.NewMainPageBottom-wrap {
  width: 100%;
  cursor: pointer;
}
.blank {
  width: 67px;
}
.text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
<style lang="scss"></style>
