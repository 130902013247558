<template>
  <div class="DialogAudioPlayer-wrap">
    <!-- track-color="#F81667" color="#F81667" -->
    <div class="progress-wrap">
      <progress ref="progress" class="progress" :value="progress * 100" :max="100" />
      <div class="progress-control" @click="onProgressBarClick">
        <span class="progress-point">O</span>
      </div>
    </div>
    <div class="btn-wrap">
      <!-- 播放鍵與暫停鍵 -->
      <q-icon v-if="playingAudio" class="stopBtn" name="stop" @click="controlBtn" />
      <img v-else :ref="'playBtn'" class="playBtn" :class="{ disableBtn: !audioReady }" src="../../assets/img/pinkl_play_btn.png" alt="play_btn" @click="controlBtn" />
    </div>
    <!-- 音檔(不顯示) -->
    <audio v-show="false" ref="audio" controls :src="src"></audio>
  </div>
</template>

<script>
import { checkAudioAvailable } from '@/lib/utils';

export default {
  props: ['src'],
  data() {
    return {
      playingAudio: false,
      audioReady: false,
      currentTime: 0,
      duration: Infinity
    };
  },
  computed: {
    progress() {
      return this.currentTime / this.duration;
    }
  },
  watch: {
    src() {
      this.duration = Infinity;
      this.audioReady = false;
      this.checkAudio();
    }
  },
  mounted() {
    this.checkAudio();
    this.$refs.audio.addEventListener('timeupdate', () => {
      this.currentTime = this.$refs.audio.currentTime;
      // console.log('timeupdate', this.currentTime, this.duration);
    });
  },
  methods: {
    controlBtn() {
      if (this.playingAudio) {
        this.pauseAudioMedia();
      } else {
        // 若沒設定音檔連結，則自動忽略
        if (this.audioReady) {
          this.startAudioMedia();
        }
      }
    },
    onProgressBarClick(e) {
      let x = e.pageX - e.offsetLeft, // or e.offsetX (less support, though)
        y = e.pageY - e.offsetTop, // or e.offsetY
        clickedValue = (x * e.max) / e.offsetWidth;

      console.log(e, x, y, clickedValue);
    },
    checkAudio() {
      return checkAudioAvailable(this.src)
        .then(result => {
          if (result.src === this.src) {
            let meta = result.meta;
            this.duration = meta.duration;
            console.log('audio ready', meta.duration);
            this.currentTime = 0;
            this.audioReady = true;
          }
        })
        .catch(() => {
          console.log('check audio failed');
          this.audioReady = false;
        });
    },
    // 播放音檔
    startAudioMedia() {
      const audio = this.$refs.audio;
      this.$nextTick(() => {
        audio.play();
        this.playingAudio = true;
      });
    },
    // 暫停音檔
    pauseAudioMedia() {
      this.playingAudio = false;
      const audio = this.$refs.audio;
      audio.pause();
    }
  }
};
</script>

<style lang="scss" scoped>
.DialogAudioPlayer-wrap {
  .progress {
    width: 100%;
    background-color: #f81667;
    height: 2px;
    &::-webkit-progress-value {
      background-color: #f81667;
    }
    &::-moz-progress-bar {
      background-color: #f81667;
    }
  }
  .progress-contorl {
    position: relative;
    width: 100%;
    top: -10px;
    height: 5px;
    .progress-point {
    }
  }
}
.btn-wrap {
  margin-top: 12px;
  height: 28px;
}
.stopBtn {
  background: linear-gradient(0deg, #fd3a84 0%, #fdcfc2 100%);
  color: #fbd8de;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  line-height: 28px;
  font-size: 18px;
}
.playBtn {
  height: 29px;
  border-radius: 29px;
  &.animation {
    animation: heartbeat 7.5s infinite;
  }
}
.disableBtn {
  opacity: 0.4;
}
</style>
