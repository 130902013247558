<template>
  <div>
    <div class="WaveBg-wrap">
      <ItemWave1 class="ItemWave1-component" :r="229" :g="220" :b="232" :opacity="0.5" :multipleWave="false" />
    </div>
  </div>
</template>

<script>
import ItemWave1 from '../MainPage/ItemWave1.vue';
export default {
  name: 'WaveBg',
  props: [],
  components: { ItemWave1 },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.WaveBg-wrap {
  width: 100%;
  height: 100%;
  background: rgba(229, 220, 232, 0.5);
  position: relative;
}
.ItemWave1-component {
  width: 100%;
  bottom: 100%;
}
</style>
<style lang="scss"></style>
