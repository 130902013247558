<template>
  <div class="AnalyzeResult-wrap">
    <!-- 標題 -->
    <div class="myTitle">
      {{ title }}
    </div>
    <!-- 以下為分析結果會出現的文字 -->
    <div class="sAnalyze-container" v-if="text_1" v-html="text_1"></div>
    <br />
    <div class="sAnalyze-container" v-if="text_2" v-html="text_2"></div>

    <!-- 以下為助人金句會出現的文字 -->
    <div v-if="disadvantage" class="disadvantageSentence sAnalyze-container">
      我覺得
      <span class="highlight-02">「{{ disadvantage }}」</span>
      這個缺點如果用對地方，就會是
    </div>
    <template v-if="content">
      <!-- 文字內容 -->
      <div class="content">{{ content }}</div>
      <!-- 播放鍵與暫停鍵 -->
      <div class="btn-wrap">
        <div v-if="startAudio" class="stopBtn-wrap">
          <img src="../../assets/img/heart_bg.png" alt="" />
          <q-icon class="stopBtn" name="stop" @click="audioControlBtn()" />
        </div>
        <img v-else class="playBtn" :class="{ disableBtn: !myAudio }" src="../../assets/img/pinkl_play_btn_heart.png" alt="play_btn" @click="audioControlBtn()" />
      </div>
    </template>

    <!-- 音檔(不顯示) -->
    <audio v-show="false" :ref="'myAudio'" controls :src="myAudioSrc"></audio>
  </div>
</template>

<script>
import { parseAudioLink } from '@/lib/utils';

export default {
  name: 'AnalyzeResult',
  props: ['title', 'text_1', 'text_2', 'disadvantage', 'content', 'myAudio'],
  components: {},
  computed: {
    myAudioSrc() {
      return parseAudioLink(this.myAudio);
    }
  },
  data() {
    return {
      startAudio: false
    };
  },
  mounted() {
    const self = this;
    const myAudio = this.$refs.myAudio;
    // 監聽音檔結束
    myAudio.addEventListener(
      'ended',
      function() {
        self.startAudio = false;
      },
      false
    );
  },
  methods: {
    audioControlBtn() {
      if (this.startAudio) {
        this.pauseAudioMedia();
      } else {
        if (this.myAudio) {
          this.startAudioMedia();
        }
      }
    },
    // 播放音檔
    startAudioMedia() {
      this.startAudio = true;
      const myAudio = this.$refs.myAudio;
      myAudio.play().catch(() => {
        self.startAudio = false;
      });
    },
    // 暫停音檔
    pauseAudioMedia() {
      this.startAudio = false;
      const myAudio = this.$refs.myAudio;
      myAudio.pause();
    }
  },
  beforeDestroy() {
    const self = this;
    const myAudio = this.$refs.myAudio;
    myAudio.removeEventListener(
      'ended',
      function() {
        self.startAudio = false;
      },
      false
    );
  }
};
</script>
<style scoped lang="scss">
.AnalyzeResult-wrap {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  width: 90.67%;
  color: #2d234c;
  margin: 0 auto;
  padding: 23px 10px 13px 18px;
}
.myTitle {
  width: 149px;
  height: 24px;
  color: #625f74;
  background: #ecaca7;
  border-radius: 50px;
  margin: -36px auto 11px auto;
  text-align: center;
  padding-top: 2.5px;
}
.disadvantageSentence {
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
  color: #868686;
}
@mixin mini_scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(182, 183, 205, 1);
    border-radius: 7px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(182, 183, 205, 1);
  }
}
.content {
  overflow-y: auto;
  height: 54px;
  @include mini_scroll;
  color: #e4706e;
  font-weight: 500;
  font-size: 18px;
  padding: 0 12px;
}
.btn-wrap {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 55px;
  margin-left: auto;
  margin-right: 7px;
}
.playBtn {
  height: 29px;
  // margin-left: auto;
  // margin-right: 7px;
  // display: flex;
  animation: heartbeat 7.5s infinite;
}
// 類似心跳的動畫
@keyframes heartbeat {
  0% {
    opacity: 0.7;
    height: 29px;
    width: 29px;
  }
  13% {
    opacity: 1;
    height: 32px;
    width: 32px;
  }
  20% {
    opacity: 1;
    height: 32px;
    width: 32px;
  }
  33% {
    opacity: 0.7;
    height: 29px;
    width: 29px;
  }
  100% {
    opacity: 0.7;
    height: 29px;
    width: 29px;
  }
}
.stopBtn-wrap {
  position: relative;
  height: 29px;
  .stopBtn {
    position: absolute;
    color: #fbd8de;
    font-size: 14px;
    right: 11px;
    top: 7px;
  }
}
.disableBtn {
  animation: none;
  opacity: 0.4;
}
</style>
<style lang="scss"></style>
